import { isValidFormat } from "./isValidFormat";
import gtag from "ga-gtag";

export function handleCalculate(
  required,
  values,
  navigate,
  calculate,
  setErrorFields,
  setResultvalues,
  setOpen
) {
  // ****************************************
  // Show Additional Fields
  // ****************************************
  const missing = [];

  // NOTLÖSUNG AGE INPUT
  function isNumber(s) {
    for (let i = 0; i < s.length; i++)
      if (s[i] < "0" || s[i] > "9") return false;

    return true;
  }
  if (isValidFormat(values.bir, "date") !== "") {
    missing.push(required.age[0]);
  }

  // MISSING FIELD FOR NEW TAX AT SOURCE
  for (let i = 0; i < required.default.length; i++) {
    if (
      values[required.default[i]] === "0" ||
      values[required.default[i]] === ""
    ) {
      missing.push(required.default[i]);
    }
  }
  if (values.ckt) {
    for (let i = 0; i < required.quell.length; i++) {
      if (
        values[required.quell[i]] === null ||
        values[required.quell[i]] === ""
      ) {
        missing.push(required.quell[i]);
      }
    }
  }

  if (values.ckt) {
    const cantonTarCodeF = ["GR", "TI", "VS"];
    if (values.tar === "F") {
      if (!cantonTarCodeF.includes(values.can)) {
        missing.push(required.tarCodeError[0]);
      }
    }

    if (values.can === "TI") {
      if (values.tar === "M" || values.tar === "N" || values.tar === "P") {
        missing.push(required.tarCodeError[0]);
      }
    }

    if (values.can === "GE") {
      if (
        values.tar === "M" ||
        values.tar === "N" ||
        values.tar === "P" ||
        values.tar === "L" ||
        values.tar === "Q"
      ) {
        missing.push(required.tarCodeError[0]);
      }
    }

    const cantonTarCodeTi = ["R", "S", "T", "U"];
    if (values.can !== "TI" && cantonTarCodeTi.includes(values.tar)) {
      missing.push(required.tarCodeError[0]);
    }
  }

  const cantonTarCodeSF = ["BE", "BS", "BL", "JU", "NE", "SO", "VD", "VS"];
  if (values.tar === "SF" && !cantonTarCodeSF.includes(values.can)) {
    missing.push(required.tarCodeError[0]);
  }

  if (values.intern && values.vac === "4_wochen" && values.age === "0_19") {
    missing.push(required.ferien_anspruch[0]);
  }

  // ****************************************
  // determine whether missing or calculate
  // ****************************************
  let path = "?";
  let index = 0;
  for (let key in values) {
    path = index > 0 ? path + "&" : path;
    path = path + key + "=" + values[key];
    index++;
  }

  if (missing.length === 0) {
    const currentDate = new Date();
    const date = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear() + 1}`;
    values.date = date;

    gtag("event", "Berechnung");

    navigate(path);
    //calculate
    calculate(values);
    setResultvalues(values);
    setErrorFields([]);
    setOpen(true);
  } else {
    setErrorFields(missing);
  }
}
